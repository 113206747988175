import { useEffect } from "react";

interface AdinPlayAdProps {
  placementId: string;
}

export default function AdinPlayAd(props: AdinPlayAdProps) {
  const { placementId } = props;

  useEffect(() => {
    (window as any).aiptag?.cmd?.display?.push(function () {
      (window as any).aipDisplayTag.display(placementId);
    });

    return () => {
      (window as any).aiptag?.cmd?.display?.push(function () {
        (window as any).aipDisplayTag.destroy(placementId);
      });
    };
  }, [placementId]);

  return <div id={placementId} />;
}
