import { useMemo } from "react";

export enum Role {
    Management = "Management",
    HeadOfGame = "Head of Game",
    HeadOfResearchAndTechnology = "Head of Research & Technology",
    DeputyHeadOfResearchAndTechnology = "Deputy Head of Research & Technology",
    HeadOfModerationAndHelpDesk = "Head of Moderation & Help Desk",
    DeputyHeadOfModerationAndHelpDesk = "Deputy Head of Moderation & Help Desk",
    HeadOfInnovationAndCommunity = "Head of Innovation & Community",
    DeputyHeadOfInnovationAndCommunity = "Deputy Head of Innovation & Community",
    HeadOfConstructionAndDesign = "Head of Construction & Design",
    DeputyHeadOfConstructionAndDesign = "Deputy Head of Construction & Design",
    SeniorDeveloper = "Senior Developer",
    Developer = "Developer",
    JuniorDeveloper = "Junior Developer",
    SeniorQualityAssurance = "Senior Quality Assurance",
    QualityAssurance = "Quality Assurance",
    JuniorQualityAssurance = "Junior Quality Assurance",
    SeniorModerator = "Senior Moderator",
    Moderator = "Moderator",
    JuniorModerator = "Junior Moderator",
    SeniorHelper = "Senior Helper",
    Helper = "Helper",
    JuniorHelper = "Junior Helper",
    SeniorInnovator = "Senior Innovator",
    Innovator = "Innovator",
    JuniorInnovator = "Junior Innovator",
    SeniorConstructor = "Senior Constructor",
    Constructor = "Constructor",
    JuniorConstructor = "Junior Constructor",
    Designer = "Designer",
    Team = "Team",
    VIPPlus = "VIP+",
    VIP = "VIP",
    NextGlobeOneUltra = "NextGlobe One Ultra",
    NextGlobeOneStandard = "NextGlobe One Standard",
    NextGlobeOneBasic = "NextGlobe One Basic",
    NextGlobeOne = "NextGlobe One",
    Member = "Member"
}

const weights = {
    [Role.Management]: 10000,
    [Role.HeadOfGame]: 9000,
    [Role.HeadOfResearchAndTechnology]: 8550,
    [Role.DeputyHeadOfResearchAndTechnology]: 8500,
    [Role.HeadOfModerationAndHelpDesk]: 8450,
    [Role.DeputyHeadOfModerationAndHelpDesk]: 8400,
    [Role.HeadOfInnovationAndCommunity]: 8350,
    [Role.DeputyHeadOfInnovationAndCommunity]: 8300,
    [Role.HeadOfConstructionAndDesign]: 8250,
    [Role.DeputyHeadOfConstructionAndDesign]: 8200,
    [Role.SeniorDeveloper]: 6900,
    [Role.Developer]: 6600,
    [Role.JuniorDeveloper]: 6300,
    [Role.SeniorQualityAssurance]: 6200,
    [Role.QualityAssurance]: 6100,
    [Role.JuniorQualityAssurance]: 6000,
    [Role.SeniorModerator]: 5900,
    [Role.Moderator]: 5600,
    [Role.JuniorModerator]: 5300,
    [Role.SeniorHelper]: 4900,
    [Role.Helper]: 4600,
    [Role.JuniorHelper]: 4300,
    [Role.SeniorInnovator]: 3900,
    [Role.Innovator]: 3600,
    [Role.JuniorInnovator]: 3300,
    [Role.SeniorConstructor]: 2900,
    [Role.Constructor]: 2600,
    [Role.JuniorConstructor]: 2300,
    [Role.Designer]: 1600,
    [Role.Team]: 500,
    [Role.VIPPlus]: 300,
    [Role.VIP]: 200,
    [Role.NextGlobeOneUltra]: 100,
    [Role.NextGlobeOneStandard]: 80,
    [Role.NextGlobeOneBasic]: 60,
    [Role.NextGlobeOne]: 50,
    [Role.Member]: 0
}

export default function useHighestRole(roles?: string[]): Role | undefined {
    const highestRole = useMemo(() => {
        return getHighestRole(roles);
    }, [roles]);

    return highestRole as Role;
}

export function getHighestRole(roles?: string[]): Role | undefined {
    return roles && (roles.filter(r => weights[r] !== undefined).sort((a, b) => weights[b] - weights[a])[0] as Role);
}