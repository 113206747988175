import useSessionToken from "./sessiontoken/useSessionToken";
import useRoomOwner from "../swr/useRoomOwner";
import usePlayerById from "../swr/usePlayerById";
import usePlayer from "../swr/usePlayer";
import {getHighestRole, Role} from "./use-highest-role";

export function useShowAds(roomId?: string) {
    const sessionToken = useSessionToken();
    const { ownerData } = useRoomOwner(sessionToken, roomId);
    const { player: owner, loading: ownerLoading } = usePlayerById(sessionToken, ownerData?.owner);
    const { player: self, loading: selfLoading } = usePlayer(sessionToken);

    function dontShowAdsBecauseOfOwner() {
        if(owner) {
            const highestRole = getHighestRole(owner.roles);
            // Don't show to players when owner has NextGlobe One Standard or Ultra
            return highestRole === Role.NextGlobeOneStandard || highestRole === Role.NextGlobeOneUltra;
        } else {
            // During loading, don't show ads. If data failed to load, show ads
            return ownerLoading;
        }
    }

    if(self) {
        const highestRole = getHighestRole(self.roles);
        // Show ads to players having no role or member role
        return (!highestRole || highestRole === Role.Member) && !dontShowAdsBecauseOfOwner();
    } else {
        // During loading, don't show ads. If data failed to load, show ads
        return !selfLoading;
    }
}