import React, { Fragment, ReactNode } from "react";
import { Box, Divider, Theme, Typography, useTheme } from "@mui/material";
import { LayoutGroup, motion } from "framer-motion";
import { SxProps, SystemStyleObject } from "@mui/system";

const controlSegmentStyles: SystemStyleObject<Theme> = {
    borderRadius: 8,
    textAlign: "center",
    padding: "2px",
    cursor: "pointer",
    userSelect: "none"
}

const absoluteHackStyles: SystemStyleObject<Theme> = {
    boxSizing: "border-box",
    width: "100%",
    flex: "none"
}

const controlSegmentTextStyles: SystemStyleObject<Theme> = {
    zIndex: 1,
    textAlign: "center",
    userSelect: "none",
    wordBreak: "break-word",
    hyphens: "auto"
}

const dividerVariants = {
    visible: {
        opacity: 1
    },
    hidden: {
        opacity: 0
    }
}

interface SegmentedControlValue {
    key: string;
    label: string;
    sub?: ReactNode;
}

interface SegmentedControlProps {
    id: string;
    value: string;
    onChange: (newValue: string) => void;
    values: SegmentedControlValue[];
    grow?: boolean;
    size?: "normal" | "big";
    fontWeight?: string;
    animate?: boolean;
    wrap?: boolean;
    minWidth?: string;
    sx?: SxProps<Theme>
}

export default function SegmentedControl(props: SegmentedControlProps) {
    const { id, value: selected, onChange, values, grow, size, fontWeight, animate, wrap, minWidth, sx = [] } = props;

    const theme = useTheme();

    return <Box
        sx={[
            {
                borderRadius: "8px",
                background: theme.palette.mode === "dark" ? "#2E3338" : "#DADADA",
                display: "flex",
                alignItems: "center",
                flexWrap: wrap ? "wrap" : "initial",
                width: "fit-content"
            },
            ...(Array.isArray(sx) ? sx : [sx])
        ]}
    >
        <LayoutGroup id={id}>
            {values.map((value, idx) => {
                return <Fragment key={value.key}>
                    <Box sx={[
                        {
                            display: "flex",
                            alignItems: "center",
                            flexFlow: "row nowrap",
                            height: "auto",
                            alignSelf: "stretch",
                            minWidth: minWidth ?? "auto"
                        },
                        selected !== value.key ? controlSegmentStyles : { padding: "2px", cursor: "pointer" },
                        grow && { flexGrow: 1 }
                    ]} onClick={() => onChange(value.key)}>
                        <Box display="flex" flexDirection="column" width="100%" padding={size === "big" ? "16px 32px" : "8px 16px"}>
                            <Typography variant="body2" sx={[
                                absoluteHackStyles,
                                controlSegmentTextStyles,
                                fontWeight && { fontWeight }
                            ]}>
                                {value.label}
                            </Typography>
                            {value.sub && <Typography variant="body2" color="textSecondary" sx={[
                                absoluteHackStyles,
                                controlSegmentTextStyles
                            ]}>
                                {value.sub}
                            </Typography>}
                        </Box>
                        {selected === value.key && <Box
                            component={motion.div}
                            sx={[
                                absoluteHackStyles,
                                {
                                    height: "auto",
                                    alignSelf: "stretch"
                                },
                                {
                                    marginLeft: "-100%"
                                },
                                {
                                    background: theme.palette.background.paper,
                                    padding: "2px 0",
                                    borderRadius: "8px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    boxShadow: "1px 1px 8px -2px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0,0,0,0.1),1px 1px 8px -1px rgba(0, 0, 0, 0.08)"
                                }
                            ]}
                            layoutId="selected"
                            initial={animate ? { opacity: 0 } : false}
                            animate={{ opacity: 1, transition: { duration: 0.08, ease: "linear" } }}
                        />}
                    </Box>
                    {idx < values.length - 1 && <Box sx={[
                        {
                            height: "auto",
                            alignSelf: "stretch"
                        },
                        {
                            width: "1px"
                        }
                    ]}>
                        <Box component={motion.div} sx={{
                            display: "flex",
                            height: "100%"
                        }} animate={selected !== value.key && selected !== values[idx + 1].key ? "visible" : "hidden"} variants={dividerVariants}>
                            <Divider orientation="vertical" flexItem sx={{ margin: "8px 0px" }} />
                        </Box>
                    </Box>}
                </Fragment>
            })}
        </LayoutGroup>
    </Box>
}