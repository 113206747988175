import { Box, BoxProps, BoxTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { forwardRef } from "react";

const Card = forwardRef<any, BoxProps>(function Card(props: BoxProps, ref: any) {
    const { sx, children, ...other } = props;

    return <Box
        ref={ref}
        sx={[
            {
                borderRadius: "16px",
                backgroundColor: "background.paper",
                padding: "24px",
                boxShadow: 2
            },
            ...(Array.isArray(sx) ? sx : [sx])
        ]}
        {...other}
    >
        {children}
    </Box>
}) as OverridableComponent<BoxTypeMap>;

export default Card;